body { background-color: $COL1; }
body { color: $COL7; }
[data-pad="0"] { margin-top: 20px; }

[data-pad="1"] { margin-top: 5px; }

h1 {
color: $COL12;
font-family: 'Poppins';
font-weight: 600;
font-style: normal;
line-height: 1.5;
font-size: 25px;

@media #{$medium-up} {
font-size: 40px;

}
}
h2 {
color: $COL13;
font-family: 'Poppins';
font-weight: 600;
font-style: normal;
line-height: 1.5;
font-size: 22px;

@media #{$medium-up} {
font-size: 35px;

}
}
h3 {
color: $COL14;
font-family: 'Poppins';
font-weight: 600;
font-style: normal;
line-height: 1.5;
font-size: 20px;

@media #{$medium-up} {
font-size: 30px;

}
}
h4 {
color: $COL15;
font-family: 'Poppins';
font-weight: 600;
font-style: normal;
line-height: 1.5;
font-size: 18px;

@media #{$medium-up} {
font-size: 25px;

}
}
h5 {
color: $COL16;
font-family: 'Poppins';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 18px;

@media #{$medium-up} {
font-size: 20px;

}
}
h6 {
color: $COL17;
font-family: 'Poppins';
font-weight: 600;
font-style: normal;
line-height: 1.5;
font-size: 16px;

@media #{$medium-up} {
font-size: 18px;

}
}
.button {
font-family: 'Poppins';
font-weight: normal;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
font-size: 20px;

}
.me-Quote .quote-body {
font-family: 'Arapey';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
.me-Quote .quote-author {
font-family: 'Poppins';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul {
color: $COL11;
font-family: 'Poppins';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 16px;

}
ol,ul { margin-bottom: 1em; list-style-position: outside; }
ol { margin-left: 1.25em; list-style-type: decimal; }
ul { margin-left: 1.25em; list-style-type: disc; }
ul, ol { & & { margin-bottom: 0 } }
cite {
font-family: 'Poppins';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
summary {
font-family: 'Poppins';
font-weight: 600;
font-style: normal;
line-height: 1.5;
font-size: 18px;

@media #{$medium-up} {
font-size: 25px;

}
}
nav.mainmenu > .menu-item > a {
font-family: 'Poppins';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 16px;

}
nav.mainmenu > .menu-item > div > a {
font-family: 'Poppins';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 16px;

}
nav.secondarymenu > .menu-item > a {
font-family: 'Poppins';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.me-RespMenu.responsive-menu a {
font-family: 'Poppins';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
body {
font-family: 'Poppins';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$large-up} {
font-size: 16px;

}
}
p { padding-bottom: 1em; }
a {color: $COL8}
a:hover {color: $COL26}
/* Primary:3 */
.MES3 {
background-color: $COL2;
color: $COL9;
 }
/* Primary:4 */
.MES4 {
background-color: $COL2;
color: $COL9;
 }
.MES4 {
background-color: $COL2;
color: $COL9;
h1.MEC4, h2.MEC4, h3.MEC4, h4.MEC4, h5.MEC4, h6.MEC4 { color: $COL9;
 }
 }
cite.MEC4{
color: $COL9;
}
/* Secondary:5 */
.MES5 {
background-color: $COL3;
color: $COL10;
 }
/* Secondary:6 */
.MES6 {
background-color: $COL3;
color: $COL10;
 }
.MES6 {
background-color: $COL3;
color: $COL10;
h1.MEC6, h2.MEC6, h3.MEC6, h4.MEC6, h5.MEC6, h6.MEC6 { color: $COL10;
 }
 }
cite.MEC6{
color: $COL10;
}
/* Dark:7 */
.MES7 {
background-color: $COL4;
color: $COL18;
 }
/* Dark:8 */
.MES8 {
background-color: $COL22;
color: $COL1;
 }
.MES8 {
background-color: $COL22;
color: $COL1;
h1.MEC8, h2.MEC8, h3.MEC8, h4.MEC8, h5.MEC8, h6.MEC8 { color: $COL1;
 }
 }
a.MEC8 { color: $COL5;
&:hover { color: $COL3; }
 }
cite.MEC8{
color: $COL1;
}
/* Light:9 */
.MES9 {
background-color: $COL5;
 }
/* Light:10 */
.MES10 {
background-color: $COL5;
padding: 20px 15px;

 }
.MES10 {
background-color: $COL5;
padding: 20px 15px;

 }
/* Shade 1:11 */
.MES11 {
background-color: $COL6;
 }
/* Shade 1:12 */
.MES12 {
background-color: $COL6;
 }
.MES12 {
background-color: $COL6;
 }
/* Menu:13 */
.MES13 {
background-color: $COL3;
color: $COL1;
font-size: 21px;
@media #{$medium-up} {
font-size: 19.6px;
};
@media #{$large-up} {
font-size: 24px;
};
border-width: 0 0 5px 0;
border-style: solid;
border-color: transparent transparent $COL27 transparent;
 }
.MES13 {
background-color: $COL3;
color: $COL1;
font-size: 21px;
@media #{$medium-up} {
font-size: 19.6px;
};
@media #{$large-up} {
font-size: 24px;
};
border-width: 0 0 5px 0;
border-style: solid;
border-color: transparent transparent $COL27 transparent;
h1.MEC13, h2.MEC13, h3.MEC13, h4.MEC13, h5.MEC13, h6.MEC13 { color: $COL1;
 }
 }
a.MEC13 { color: $COL1;
&:hover { color: $COL27; }
 }
cite.MEC13{
color: $COL1;
font-size: 21px;
@media #{$medium-up} {
font-size: 19.6px;
};
@media #{$large-up} {
font-size: 24px;
};
}
/* home block blue:14 */
.MES14 {
background-color: $COL22;
&:hover, &.hover { background-color: $COL23;}
& > .underlay, & > .inner-overlay { opacity: 0.3 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://palletsandcrates.com.au/img/1555/31');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: $COL20;
min-height:300px;
@media #{$medium-up} {
min-height: 300px;};
@media #{$large-up} {
min-height: 300px;};
border-width: 10px;
border-style: solid;
border-color: $COL27;
 }
.MES14 {
background-color: $COL22;
&:hover, &.hover { background-color: $COL23;}
& > .underlay, & > .inner-overlay { opacity: 0.3 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://palletsandcrates.com.au/img/1555/31');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: $COL20;
min-height:300px;
@media #{$medium-up} {
min-height: 300px;};
@media #{$large-up} {
min-height: 300px;};
border-width: 10px;
border-style: solid;
border-color: $COL27;
h1.MEC14, h2.MEC14, h3.MEC14, h4.MEC14, h5.MEC14, h6.MEC14 { color: $COL20;
 }
 }
.MEC14 li {color: $COL20;}
cite.MEC14{
color: $COL20;
}
/* :15 */
.MES15 {
background-color: $COL20;
color: $COL4;
border-width: 5px 0 0 0;
border-style: solid;
border-color: $COL3 transparent transparent transparent;
 }
.MES15 {
background-color: $COL20;
color: $COL4;
border-width: 5px 0 0 0;
border-style: solid;
border-color: $COL3 transparent transparent transparent;
h1.MEC15, h2.MEC15, h3.MEC15, h4.MEC15, h5.MEC15, h6.MEC15 { color: $COL4;
 }
 }
.MEC15 li {color: $COL4;}
cite.MEC15{
color: $COL4;
}
/* Hollaw:16 */
.MES16 {
color: $COL20;
&:hover { color: $COL20;}
border-width: 2px;
border-style: solid;
border-color: $COL20;
&:hover { border-color: $COL20; }
 }
/* :17 */
.MES17 {
 }
/* Contents:18 */
.MES18 {
background-color: $COL20;
&:hover, &.hover { background-color: $COL20;}
color: $COL4;
font-size: 14px;
@media #{$large-up} {
font-size: 16px;
};
 }
.MES18 {
background-color: $COL20;
&:hover, &.hover { background-color: $COL20;}
color: $COL4;
font-size: 14px;
@media #{$large-up} {
font-size: 16px;
};
 }
a.MEC18 { color: $COL3;
&:hover { color: $COL6; }
 }
cite.MEC18{
color: $COL4;
font-size: 14px;
@media #{$large-up} {
font-size: 16px;
};
}
/* Slider text 2:19 */
.MES19 {
 }
.MES19 {
h1.MEC19, h2.MEC19, h3.MEC19, h4.MEC19, h5.MEC19, h6.MEC19 { color: $COL22;
 }
h1.MEC19 { @media #{$medium-up} { font-size: 44px; }; }
h1.MEC19 { @media #{$large-up} { font-size: 48px; }; }
h2.MEC19 { @media #{$medium-up} { font-size: 38.5px; }; }
h2.MEC19 { @media #{$large-up} { font-size: 42px; }; }
h3.MEC19 { @media #{$medium-up} { font-size: 33px; }; }
h3.MEC19 { @media #{$large-up} { font-size: 36px; }; }
h4.MEC19 { @media #{$medium-up} { font-size: 27.5px; }; }
h4.MEC19 { @media #{$large-up} { font-size: 30px; }; }
h5.MEC19 { @media #{$medium-up} { font-size: 22px; }; }
h5.MEC19 { @media #{$large-up} { font-size: 24px; }; }
h6.MEC19 { @media #{$medium-up} { font-size: 19.8px; }; }
h6.MEC19 { @media #{$large-up} { font-size: 21.6px; }; }
 }
/* Footer Dark:20 */
.MES20 {
background-color: $COL22;
color: $COL1;
font-size: 11.2px;
@media #{$large-up} {
font-size: 12.8px;
};
 }
.MES20 {
background-color: $COL22;
color: $COL1;
font-size: 11.2px;
@media #{$large-up} {
font-size: 12.8px;
};
h1.MEC20, h2.MEC20, h3.MEC20, h4.MEC20, h5.MEC20, h6.MEC20 { color: $COL1;
 }
 }
a.MEC20 { color: $COL5;
&:hover { color: $COL3; }
 }
cite.MEC20{
color: $COL1;
font-size: 11.2px;
@media #{$large-up} {
font-size: 12.8px;
};
}
/* News grid:21 */
.MES21 {
color: $COL4;
border-width: 1px;
border-style: solid;
border-color: $COL1 $COL5;
 }
.MES21 {
color: $COL4;
border-width: 1px;
border-style: solid;
border-color: $COL1 $COL5;
h1.MEC21, h2.MEC21, h3.MEC21, h4.MEC21, h5.MEC21, h6.MEC21 { color: $COL22;
 }
 }
cite.MEC21{
color: $COL4;
}
/* About FAQ:22 */
details.MES22 {
& > summary{background-color: $COL5;
}
& > summary{padding: 10px 0;}

& > article {border-width: 0 0 1px 0;
border-style: solid;
border-color: transparent transparent $COL20 transparent;
} }
/* Home FAQ:23 */
details.MES23 {
& > summary{background-color: $COL2;
}
color: $COL20;
& > article { color: $COL20;
 }
& > summary{padding: 10px 0;}

& > article {border-width: 0 0 1px 0;
border-style: solid;
border-color: transparent transparent $COL20 transparent;
} }
/* Secondary:24 */
.MES24 {
color: $COL22;
border-width: 2px;
border-style: solid;
border-color: $COL20;
 }
/* slider form:25 */
.MES25 {
background-color: $COL3;
color: $COL20;
font-size: 14px;
@media #{$large-up} {
font-size: 16px;
};
padding: 10px;

@media #{$medium-up} {
padding: 15px;

}
 }
.MES25 {
background-color: $COL3;
color: $COL20;
font-size: 14px;
@media #{$large-up} {
font-size: 16px;
};
padding: 10px;

@media #{$medium-up} {
padding: 15px;

}
h1.MEC25, h2.MEC25, h3.MEC25, h4.MEC25, h5.MEC25, h6.MEC25 { color: $COL20;
 }
h1.MEC25 { @media #{$large-up} { font-size: 34px; }; }
h2.MEC25 { @media #{$large-up} { font-size: 29.75px; }; }
h3.MEC25 { @media #{$large-up} { font-size: 25.5px; }; }
h4.MEC25 { @media #{$large-up} { font-size: 21.25px; }; }
h5.MEC25 { @media #{$large-up} { font-size: 17px; }; }
h6.MEC25 { @media #{$large-up} { font-size: 15.3px; }; }
 }
a.MEC25 { color: $COL20;
&:hover { color: $COL5; }
 }
cite.MEC25{
color: $COL20;
font-size: 14px;
@media #{$large-up} {
font-size: 16px;
};
}
/* Buttons:27 */
.MES27 {
background-color: $COL3;
&:hover { background-color: $COL2;}
color: $COL10;
&:hover { color: $COL22;}
font-size: 22px;
@media #{$large-up} {
font-size: 24px;
};
padding: 20px;

 }
/* Line break:28 */
.MES28 {
& > hr {border-width: 1px;}
& > hr {border-color: $COL27;}
& > hr {border-top-style: dashed;}
& > hr {border-right-style: dashed;}
& > hr {border-bottom-style: dashed;}
& > hr {border-left-style: dashed;}
 }
/* Shade 2:29 */
.MES29 {
background-color: $COL27;
 }
/* Shade 2:30 */
.MES30 {
background-color: $COL27;
 }
.MES30 {
background-color: $COL27;
 }
/* Menu Top:31 */
nav.me-Menu.MES31 {
& .menu-item.MEC31, & .menu-item.MEC31 > div.MEC31{ & > a.MEC31{color: $COL1;
text-transform: uppercase;
}
 &:hover > a.MEC31{color: $COL22;
}
 }
&.horizontal > .menu-item.MEC31 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC31 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC31 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC31 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 15px;}

@media #{$large-up} {
& > .menu-item > a{padding: 5px 10px;}

}
& .sub-menu .menu-item a{padding: 5px 10px;}



.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* White transparent:32 */
.MES32 {
background-color: $COL29;
color: $COL4;
font-size: 14px;
@media #{$large-up} {
font-size: 16px;
};
padding: 30px;

 }
.MES32 {
background-color: $COL29;
color: $COL4;
font-size: 14px;
@media #{$large-up} {
font-size: 16px;
};
padding: 30px;

h1.MEC32, h2.MEC32, h3.MEC32, h4.MEC32, h5.MEC32, h6.MEC32 { color: $COL4;
 }
h1.MEC32 { @media #{$large-up} { font-size: 34px; }; }
h2.MEC32 { @media #{$large-up} { font-size: 29.75px; }; }
h3.MEC32 { @media #{$large-up} { font-size: 25.5px; }; }
h4.MEC32 { @media #{$large-up} { font-size: 21.25px; }; }
h5.MEC32 { @media #{$large-up} { font-size: 17px; }; }
h6.MEC32 { @media #{$large-up} { font-size: 15.3px; }; }
 }
a.MEC32 { color: $COL4;
&:hover { color: $COL5; }
 }
cite.MEC32{
color: $COL4;
font-size: 14px;
@media #{$large-up} {
font-size: 16px;
};
}
/* Dark Buttons:33 */
.MES33 {
background-color: $COL22;
&:hover { background-color: $COL21;}
color: $COL10;
&:hover { color: $COL22;}
font-size: 22px;
@media #{$large-up} {
font-size: 24px;
};
padding: 20px;

 }
/* Tagline Colour:34 */
.MES34 {
 }
.MES34 {
h1.MEC34, h2.MEC34, h3.MEC34, h4.MEC34, h5.MEC34, h6.MEC34 { color: $COL3;
 }
 }
/* Alternate:35 */
.MES35 {
background-color: $COL30;
 }
/* Alternate:36 */
.MES36 {
background-color: $COL30;
 }
.MES36 {
background-color: $COL30;
 }
/* Buttons2:37 */
.MES37 {
background-color: $COL3;
&:hover { background-color: $COL2;}
color: $COL10;
&:hover { color: $COL22;}
font-size: 20px;
padding: 10px 30px;

 }
/* NEW BUTTON:38 */
.MES38 {
background-color: $COL6;
 }
